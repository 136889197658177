import '../App.sass';
import React, {useEffect, useState, useContext, useRef, useMemo, createRef} from "react";
import {useLocation} from "react-router-dom";
import Button from "../components/button";
import Icons from "../components/icons";
import NotyfContext from '../context/NotfyContext';
import 'notyf/notyf.min.css';

const Home = () => {
    const location = useLocation();
    const searchInput = useRef();
    const launchUrlRefs = useMemo(
        () => Array.from({length: 100}).map(() => createRef()),[]
    );
    const notyf = useContext(NotyfContext);

    const [launchUrls, setLaunchUrls] = useState([{url: "", enabled: true}])
    const [searchTerm, setSearchTerm] = useState("")
    const [openModal, setOpenModal] = useState("")
    const [restoreTextarea, setRestoreTextarea] = useState("")
    const [lastSaveString, setLastSaveString] = useState("")

    useEffect(() => {
        searchInput.current.focus()
        let res = localStorage.getItem("launchUrls")
        if (res) {
            let jsonRes = JSON.parse(res)
            setLaunchUrls(jsonRes)
            setLastSaveString(res)
            let query = location.search.replace("?q=", "")
            if (query) {
                handleLaunch(query, jsonRes)
            }
        }
    }, [])

    useEffect(() => {
        if (launchUrls.length > 1 && launchUrls[launchUrls.length - 1].url === "") {
            launchUrlRefs[launchUrls.length - 1].current?.focus()
        }
    }, [launchUrlRefs, launchUrls])

    const addRow = () => {
        let newLaunchUrls = [...launchUrls, {url: "", enabled: true}]
        setLaunchUrls(newLaunchUrls)
    }

    const handleUrlChange = ({currentTarget: input}, i) => {
        let newLaunchUrls = [...launchUrls]
        newLaunchUrls[i].url = input.value
        setLaunchUrls(newLaunchUrls)
    }

    const handleSave = () => {
        let stringToSave = JSON.stringify(launchUrls)
        if (lastSaveString !== stringToSave) {
            localStorage.setItem("launchUrls", stringToSave)
            setLastSaveString(stringToSave)
            notyf.success({message: "Saved to localStorage", duration: 2000})
        }
    }

    const forceSave = (urls) => {
        let stringToSave = JSON.stringify(urls)
        if (lastSaveString !== stringToSave) {
            localStorage.setItem("launchUrls", stringToSave)
            setLastSaveString(stringToSave)
            notyf.success({message: "Saved to localStorage", duration: 2000})
        }
    }

    const handleSearchChange = ({currentTarget: input}) => {
        setSearchTerm(input.value)
    }

    const handleLaunch = (term = undefined, _launchUrls = undefined) => {
        for (let url of _launchUrls || launchUrls) {
            if (url.enabled && url.url) {
                let _searchTerm = term || searchTerm
                window.open(url.url.replace("%s", _searchTerm), "_blank")
            }
        }
    }

    const handleToggle = i => {
        let newLaunchUrls = [...launchUrls]
        newLaunchUrls[i].enabled = !newLaunchUrls[i].enabled
        setLaunchUrls(newLaunchUrls)
        handleSave()
    }

    const handleDelete = i => {
        let newLaunchUrls = [...launchUrls]
        newLaunchUrls.splice(i, 1)
        setLaunchUrls(newLaunchUrls)
        forceSave(newLaunchUrls)
    }

    const handleBackup = () => {
        let textarea = document.getElementById("backup-textarea")
        textarea.focus();
        textarea.select();
        document.execCommand('copy');
        notyf.success({message: "Copied JSON to clipboard", duration: 5000})
    }

    const handleOpenModal = () => {
        setOpenModal("is-active")
    }

    const handleCloseModal = () => {
        setOpenModal("")
    }

    const handleTextareaInput = ({currentTarget: input}) => {
        setRestoreTextarea(input.value)
    }

    const handleDoRestore = () => {
        try {
            let settings = JSON.parse(restoreTextarea)
            if (Array.isArray(settings)) {
                setLaunchUrls(settings)
                forceSave(settings)
                handleCloseModal()
                notyf.success({message: "Loaded JSON backup", duration: 6000})
            }
        } catch (e) {
            notyf.error({message: e, duration: 5000})
        }
    }

    return (
        <div className={"container"}>
            <div className={"m-5"}>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <figure className="image is-32x32 mr-3">
                        <img alt={""} src="/multisearchBlack.png"/>
                    </figure>
                    <p className="title">MultiSearch</p>
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <p className="subtitle">
                        Launch multiple tabs with a single search term!
                    </p>
                </div>
            </div>

            <div className={"m-6"} style={{display: "flex", justifyContent: "center"}}>
                <div className="field mr-2">
                    <div className="control">
                        <input className="input is-rounded" type="text" placeholder="🔍 Search"
                               onChange={handleSearchChange} ref={searchInput}/>
                    </div>
                </div>
                <Button svgIcon={Icons.rocket} buttonClass={"is-primary is-rounded"} text={"Launch"}
                        onClick={() => handleLaunch()}/>
            </div>
            {launchUrls.map((e, i) =>
                <div className="field m-4" key={i}>
                    <div className="control" style={{display: "flex", justifyContent: "center"}}>
                        <input
                            className="input"
                            type="text"
                            placeholder="Url to launch - %s for search term"
                            value={e.url}
                            onChange={(e) => handleUrlChange(e, i)}
                            ref={launchUrlRefs[i]}
                            onBlur={handleSave}
                        />
                        <Button svgIcon={e.enabled ? Icons.enabled : Icons.disabled}
                                buttonClass={"ml-2 " + (e.enabled ? "is-success" : "is-danger")}
                                onClick={() => handleToggle(i)}/>
                        <Button svgIcon={Icons.trash} buttonClass={"ml-2 "} onClick={() => handleDelete(i)}/>
                    </div>
                </div>
            )}
            <div className={"m-4"} style={{display: "flex", justifyContent: "flex-end"}}>
                <Button svgIcon={Icons.download} buttonClass={"is-success is-light mr-2"} text={"Restore"}
                        onClick={handleOpenModal}/>
                <Button svgIcon={Icons.copy} buttonClass={"is-link is-light mr-2"} text={"Backup"}
                        onClick={handleBackup}/>
                <Button svgIcon={Icons.plus} buttonClass={"is-info is-normal"} text={"Add"} onClick={addRow}/>
            </div>
            <div className={"modal " + openModal}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Paste backup below</p>
                        <button className="delete" aria-label="close" onClick={handleCloseModal}/>
                    </header>
                    <section className="modal-card-body">
                            <textarea style={{width: "100%"}} rows="12" autoFocus={openModal && true}
                                      value={restoreTextarea} onChange={handleTextareaInput}/>
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button is-success" onClick={handleDoRestore}>Load</button>
                        <button className="button" onClick={handleCloseModal}>Cancel</button>
                    </footer>
                </div>
            </div>
            <textarea id={"backup-textarea"} value={JSON.stringify(launchUrls)} style={{
                position: "absolute",
                left: "-10000px",
                top: "auto",
                width: "1px",
                height: "1px",
                overflow: "hidden"
            }}
            />
        </div>
    );
}

export default Home;
