import React from "react";

function getPlusSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="48"
             height="48" viewBox="0 0 24 24" stroke-width="2" stroke="#FFFFFF" fill="none"
             stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <line x1="12" y1="5" x2="12" y2="19"/>
            <line x1="5" y1="12" x2="19" y2="12"/>
        </svg>
    )
}

function getCheckSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="24"
             height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="#ffffff" fill="none"
             strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M5 12l5 5l10 -10"/>
        </svg>
    )
}

function getRocketSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-rocket" width="24"
             height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFFFFF" fill="none"
             stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path
                d="M4 13a8 8 0 0 1 7 7a6 6 0 0 0 3 -5a9 9 0 0 0 6 -8a3 3 0 0 0 -3 -3a9 9 0 0 0 -8 6a6 6 0 0 0 -5 3"/>
            <path d="M7 14a6 6 0 0 0 -3 6a6 6 0 0 0 6 -3"/>
            <circle cx="15" cy="9" r="1"/>
        </svg>
    )
}

function getEnabledSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="48"
             height="48" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFFFFF" fill="none" stroke-linecap="round"
             stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <circle cx="12" cy="12" r="9"/>
            <path d="M9 12l2 2l4 -4"/>
        </svg>
    )
}

function getDisabledSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-x" width="48" height="48"
             viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFFFFF" fill="none" stroke-linecap="round"
             stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <circle cx="12" cy="12" r="9"/>
            <path d="M10 10l4 4m0 -4l-4 4"/>
        </svg>
    )
}

function getDisabledGreySvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-x" width="48" height="48"
             viewBox="0 0 24 24" stroke-width="1.5" stroke="#cbcbcb" fill="none" stroke-linecap="round"
             stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <circle cx="12" cy="12" r="9"/>
            <path d="M10 10l4 4m0 -4l-4 4"/>
        </svg>
    )
}

function getTrashSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="48" height="48"
             viewBox="0 0 24 24" stroke-width="1.5" stroke="#f14668" fill="none" stroke-linecap="round"
             stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <line x1="4" y1="7" x2="20" y2="7"/>
            <line x1="10" y1="11" x2="10" y2="17"/>
            <line x1="14" y1="11" x2="14" y2="17"/>
            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"/>
            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"/>
        </svg>
    )
}
function getCopySvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-copy" width="48" height="48"
             viewBox="0 0 24 24" stroke-width="1.5" stroke="#2160c4" fill="none" stroke-linecap="round"
             stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <rect x="8" y="8" width="12" height="12" rx="2"/>
            <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"/>
        </svg>
    )
}
function getDownloadSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-download" width="48" height="48"
             viewBox="0 0 24 24" stroke-width="1.5" stroke="#257942" fill="none" stroke-linecap="round"
             stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"/>
            <polyline points="7 11 12 16 17 11"/>
            <line x1="12" y1="4" x2="12" y2="16"/>
        </svg>
    )
}

const Icons = {
    plus: getPlusSvg(),
    check: getCheckSvg(),
    rocket: getRocketSvg(),
    enabled: getEnabledSvg(),
    disabled: getDisabledSvg(),
    trash: getTrashSvg(),
    copy: getCopySvg(),
    download: getDownloadSvg(),
    close: getDisabledGreySvg()
}

export default Icons;
