import '../App.sass';
import React from "react";

const Button = (props) => {
    return (
        <button className={"button " + props.buttonClass} onClick={props.onClick}>
            <span className="icon">
                {props.svgIcon}
            </span>
            {props.text && <span>{props.text}</span>}
        </button>

    );
}

export default Button;
