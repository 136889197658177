import './App.sass';
import React from "react";
import {
    Route,
    Switch,
    Redirect
} from "react-router-dom";
import Home from "./views/home";


const lightSchemeIcon = document.querySelector('link#light-scheme-icon');
const darkSchemeIcon = document.querySelector('link#dark-scheme-icon');
let matcher = window.matchMedia('(prefers-color-scheme: dark)');
matcher.addListener(onUpdate);
onUpdate()

function onUpdate() {
    if (matcher.matches) {
        lightSchemeIcon.remove();
        document.head.append(darkSchemeIcon);
    } else {
        document.head.append(lightSchemeIcon);
        darkSchemeIcon.remove();
    }
}

function App() {
    return (
        <React.Fragment>
            <Switch>
                <Route
                    path="/"
                    render={() => (
                        <Home />
                    )}
                />
                <Redirect to="/"/>
            </Switch>
        </React.Fragment>
    );
}

export default App;
